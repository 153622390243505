import React, { FC, createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const ResourceSetContext = createContext({})

// Fetch all ResourceSets from CMS
const GLOBAL_RESOURCES_QUERY = graphql`
  query getAllContentfulResourceSets {
    allContentfulResourceSet {
      nodes {
        name
        key
        value {
          internal {
            content
          }
        }
      }
    }
  }
`

// Parse JSON blob from CMS ResouceSet to structured JSON to be used in components
const getStructuredResourceSets = (nodes: any[]) => {
  return nodes.map(n => {
    return {
      component: n.key,
      resources: JSON.parse(n.value.internal.content),
    }
  })
}

// Get object from specific resourceSet in CMS eg "global"
export const getResourcesForComponent = (
  resourceSets: any,
  componentName: any
) => {
  return (
    getStructuredResourceSets(resourceSets).find(
      (obj: { component: any }) => obj.component === componentName
    )?.resources ?? {}
  )
}

interface ResourceSetProviderProps {
  children: any
}

export const ResourceSetProvider: FC<ResourceSetProviderProps> = ({
  children,
}) => {
  const {
    allContentfulResourceSet: { nodes },
  } = useStaticQuery(GLOBAL_RESOURCES_QUERY)

  if (!nodes) {
    console.log("No Resource Sets found")
    return children
  }

  return (
    <ResourceSetContext.Provider
      value={{
        resourceSets: getStructuredResourceSets(nodes),
        getResourcesForComponent: (key: any) =>
          getResourcesForComponent(nodes, key),
      }}
    >
      {children}
    </ResourceSetContext.Provider>
  )
}

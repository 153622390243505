/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Import Taiwind CSS
import "./src/index.css"

// Wrap in root-wrapper to allow for global context
import { wrapRootElement as wrap } from "./root-wrapper"
export const wrapRootElement = wrap
